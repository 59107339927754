import "./outlet.css";
import { DataGrid } from "@material-ui/data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdmins } from "../../redux/apiCalls";
import moment from "moment";

export default function Outlet() {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.user.products);
    const [filterCategory] = useState(undefined);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const res = await getAdmins(dispatch, filterCategory);
            if (res) {
                setIsLoaded(true);
            }
        };
        fetchData();
    }, [dispatch, filterCategory]);

    const columns = [
        {
            field: "debtorCode",
            headerName: "Debtor Code",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="">{value ? value : ""}</div>
            ),
        },
        {
            field: "name",
            headerName: "Name",
            width: 200,
            sortable: false,
        },
        {
            field: "phoneNumber",
            headerName: "Phone Number",
            width: 300,
            sortable: false,
        },
        {
            field: "email",
            headerName: "Email",
            width: 300,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="">{value ? value : ""}</div>
            ),
        },
        {
            field: "type",
            headerName: "Type",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">
                    {value}
                </div>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">{value}</div>
            ),
        },
        {
            field: "createdAt",
            headerName: "Member Since",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">
                    {moment(value).format("DD/MM/YYYY")}
                </div>
            ),
        },
    ];

    return (
        <div className="productList">
                            {isLoaded === true && (
            <DataGrid
                rows={products}
                disableSelectionOnClick
                columns={columns}
                getRowId={(row) => row._id}
                pageSize={20}
                disableColumnMenu
            />
                            )}
        </div>
    );
}
