import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        products: [],
        quantity: 0,
        total: 0,
    },
    reducers: {
        addProduct: (state, action) => {
            const product = action.payload
            const countryPrice = process.env.REACT_APP_COUNTRY === "SINGAPORE" ? product.price : product.myprice
            state.quantity += 1;
            state.products.push({
                _id: product._id,
                title: product.title,
                color: product.activeColor,
                model: product.model,
                price: countryPrice,
                sgPrice: product.price,
                movementModel: product.movementModel,
                powerReserve: product.powerReserve,
                mirror: product.mirror,
                quantity: product.quantity,
                type: product.type,
            });
            state.total += countryPrice * action.payload.quantity;
        },
        addQuantity: (state, action) => {

            const countryPrice = process.env.REACT_APP_COUNTRY === "SINGAPORE" ? action.payload.price : action.payload.myprice
            const existingProductIndex = state.products.findIndex(
                (item) => `${item.title}-${item.color.name}` === `${action.payload.title}-${action.payload.activeColor.name}`
            );
            state.products[existingProductIndex].quantity =
            state.products[existingProductIndex].quantity +
                action.payload.quantity;



            state.total = state.total + (countryPrice * action.payload.quantity);
            if (state.products[existingProductIndex].quantity <= 0){
                state.products.splice(existingProductIndex, 1);
                state.quantity -= 1
            }
        },
        addQuantityIcon: (state, action) => {
            const countryPrice = process.env.REACT_APP_COUNTRY === "SINGAPORE" ? action.payload.sgPrice : action.payload.price
            const existingProductIndex = state.products.findIndex(
                (item) => `${item.title}-${item.color.name}` === `${action.payload.title}-${action.payload.activeColor.name}`
            );
            state.products[existingProductIndex].quantity =
            state.products[existingProductIndex].quantity +
                action.payload.quantity;

            state.total = state.total + (countryPrice * action.payload.quantity);
            if (state.products[existingProductIndex].quantity <= 0){
                state.products.splice(existingProductIndex, 1);
                state.quantity -= 1
            }
        },
        clearCart: (state) => {
            state.products = [];
            state.quantity = 0;
            state.total = 0;
            state.warranty = [];
            state.order = [];
            state.affiliate = []
          }
    },
});

export const { addProduct, addQuantity, addQuantityIcon, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
