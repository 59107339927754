import React, { useEffect } from "react";
import "./topbar.css";
import { logout } from "../../redux/userRedux";
import { ExitToApp } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

export default function Topbar() {
    const currentUser = useSelector((state) => state.user);
    const loginStamp = useSelector((state) => state.user.currentUser.loginStamp);
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(logout());
        window.location.href = "/";
    };

    useEffect(() => {
        const expiry = moment(loginStamp).add(1, "days")
        const now = moment(Date.now())
        if (!loginStamp) {
            alert("Session has expired. Please login again.")
            dispatch(logout());
        } else if (expiry.isSameOrBefore(now)) {
            alert("Session has expired. Please login again.")
            dispatch(logout());
        }
    }, [loginStamp, dispatch]);

    return (
        <div className="topbar">
            <div className="topbarWrapper">
                <div className="topLeft">
                    <a className="logo" href="/">
                        {process.env.REACT_APP_PROJECT === "HAOFA"
                            ? "Haofa System"
                            : "KLT Demo System"}
                    </a>
                </div>
                <div className="topRight">
                    <div className="marginRightSmall">Welcome, {currentUser.currentUser.name}</div>
                    <div className="topbarIconContainer">
                        <div
                            style={{
                                textDecoration: "none",
                                color: "black",
                                cursor: "pointer",
                            }}
                            className={
                                currentUser.currentUser == null
                                    ? "hidden"
                                    : null
                            }
                            onClick={handleClick}
                        >
                            <div
                                className={
                                    currentUser.currentUser == null
                                        ? "hidden"
                                        : null
                                }
                            >
                                <ExitToApp></ExitToApp>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
