import { DataGrid } from "@material-ui/data-grid";
import {
    Close,
    LocalGroceryStore,
    CloudDownload,
    RestorePage,
    VerifiedUser,
} from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrders, voidOrder } from "../../redux/apiCalls";
import moment from "moment";
import { userRequest } from "../../requestMethods";
import createPdf from "../../components/pdf/createPdf";
import createCustomerInvoice from "../../components/pdf/createCustomerInvoice";

export default function WarrantyList() {
    const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    const dispatch = useDispatch();
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );

    const currentUser = useSelector((state) => state.user.currentUser);

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const accountant = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "accountant"
            : null
    );

    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );

    const [filterCategory] = useState({
        createdBy: manager
            ? principle
            : currentUser
            ? currentUser._id
            : undefined,
        status: "pending",
    });
    const [statusMenu, setStatusMenu] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [voidMenu, setVoidMenu] = useState(false);
    const [fullpaymentMenu, setFullPaymentMenu] = useState(false);
    const [paymentType] = useState("others");
    const [modalData, setModalData] = useState([]);
    const [productList, setProductList] = useState([]);
    const [warrantyList, setWarrantyList] = useState({});
    const [allData, setAllData] = useState([]);

    let totalItems = 0;

    useEffect(() => {
        const fetchData = async () => {
            const res = await getOrders(dispatch, filterCategory);
            if (res) {
                setIsLoaded(true);
                setAllData(res.payload)
            }
        };
        fetchData();
    }, [dispatch, filterCategory]);

    useEffect(() => {
        if (statusMenu === true) {
            document.body.style.overflow = "hidden";
            window.scrollTo(0, 0);
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [statusMenu]);

    const handleVoid = (id) => {
        voidOrder(id, dispatch);
    };

    const handleFullPayment = async (id) => {
        productList.forEach((item) => {
            totalItems = totalItems + 1;
        });

        if (Object.keys(warrantyList).length !== totalItems) {
            alert(
                "Please enter the right amount of warranty for all the products"
            );
            return;
        }

        if (currentUser) {
            try {
                const res = await userRequest.post("/orders/complete", {
                    orderId: id,
                    warranty: warrantyList,
                    paymentType,
                });

                window.location.href = "/ecommerce";

                if (res.data) {
                    alert(
                        "Warranty has been confirmed and marked as shipped out."
                    );
                    setFullPaymentMenu(false);
                } else {
                    alert("Server issue, please contact support");
                    setFullPaymentMenu(false);
                }
            } catch (err) {
                alert("Warranty is invalid. Please try again.");
                setFullPaymentMenu(false);
            }
        } else {
            alert("Please login before checking out");
            setFullPaymentMenu(false);
        }
    };

    const handleWarrantyChange = (e, i) => {
        setWarrantyList((prev) => {
            return { ...prev, [i]: e.target.value };
        });
    };

    const columns = [
        {
            field: "product",
            headerName: "Products",
            width: 120,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <LocalGroceryStore
                            className="icon"
                            onClick={() => {
                                setModalData(params.row.products);
                                setStatusMenu(true);
                            }}
                        />
                    </>
                );
            },
        },
        {
            field: "userId",
            headerName: "Customer",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="initialPadding">{value ? value.name : ""}</div>
            ),
        },
        {
            field: "total",
            headerName: "Total Paid",
            width: 150,
            sortable: false,
            renderCell: (params) => {
                let total = 0;
                if (params.row.products !== undefined) {
                    for (const product of params.row.products) {
                        total = total + product.price;
                    }
                }
                return (
                    <div className="capitalize">
                        {process.env.REACT_APP_COUNTRY === "SINGAPORE"
                            ? "$"
                            : "RM"}{" "}
                        {formatter.format(total - params.row.discount)}
                    </div>
                );
            },
        },
        {
            field: "createdBy",
            headerName: "Salesperson",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">{value ? value.name : ""}</div>
            ),
        },
        {
            field: "createdAt",
            headerName: "Purchase Date",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">
                    {" "}
                    {moment(value).format("DD/MM/YYYY")}
                </div>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">{value}</div>
            ),
        },
        {
            field: "action",
            headerName: "Action",
            width: 250,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <VerifiedUser
                            className="pointer"
                            onClick={() => {
                                const original = params.row;
                                setProductList(original.products);
                                setModalData(original._id);
                                setFullPaymentMenu(true);
                            }}
                        />
                        {(superadmin || accountant) && (
                            <>
                                <CloudDownload
                                    className="pointer icon"
                                    onClick={() => {
                                        createPdf(params.row);
                                    }}
                                />
                            </>
                        )}
                        {!accountant && (
                            <Close
                                className="productListDelete pointer icon"
                                onClick={() => {
                                    setModalData(params.row._id);
                                    setVoidMenu(true);
                                }}
                            />
                        )}
                        <RestorePage
                            className="pointer icon"
                            onClick={() => {
                                createCustomerInvoice(params.row);
                            }}
                        />
                    </>
                );
            },
        },
    ];

    return (
        <>
                        {isLoaded === true && (
            <div className="productList">
                <DataGrid
                    rows={allData}
                    disableSelectionOnClick
                    columns={columns}
                    getRowId={(row) => row._id}
                    pageSize={20}
                    disableColumnMenu
                />
            </div>
                        )}

            {statusMenu && (
                <div className="overlayContainer">
                    <div className="center">
                        <div
                            className="close"
                            onClick={() => setStatusMenu(false)}
                        >
                            <Close></Close>
                        </div>
                        <div className="updatemodalContainer">
                            <div className="updateOrder">
                                <h1 className="updateProductTitle">
                                    Products({modalData.length})
                                </h1>
                                <div className="productContainer">
                                    {modalData.map((product) => {
                                        return (
                                            <div className="product">
                                                {" "}
                                                <div className="justify-center flex">
                                                <img
                                                    src={product.img}
                                                    className="productImg"
                                                    alt="productImages"
                                                />
                                                </div>
                                                <div className="productDetails">
                                                    {product.code}
                                                </div>
                                                <div className="productDetails">
                                                    {process.env
                                                        .REACT_APP_COUNTRY ===
                                                    "SINGAPORE"
                                                        ? "$"
                                                        : "RM"}
                                                    {product.price.toLocaleString(
                                                        "en-US"
                                                    )}
                                                    .00
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {voidMenu && (
                <div className="overlayContainer">
                    <div className="center">
                        <div
                            className="close"
                            onClick={() => setVoidMenu(false)}
                        >
                            <Close></Close>
                        </div>
                        <div className="actionContainer">
                            <div className="updateOrder">
                                <h3 className="updateProductTitle">
                                    Confirm Void Order?
                                </h3>
                                <div className="buttonContainer">
                                    <button
                                                                              className="confirm pointer green"
                                        onClick={() => {
                                            handleVoid(modalData);
                                            setVoidMenu(false);
                                        }}
                                    >
                                        Confirm
                                    </button>
                                    <button
                                      className="cancel pointer red"
                                        onClick={() => {
                                            setVoidMenu(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {fullpaymentMenu && (
                <div className="overlayContainer">
                    <div className="center">
                        <div
                            className="close"
                            onClick={() => setFullPaymentMenu(false)}
                        >
                            <Close></Close>
                        </div>
                        <div className="actionContainer">
                            <div className="updateOrder">
                                <h3 className="updateProductTitle">
                                    Confirm Warranty and Ship out?
                                </h3>
                                {productList.map((product, i) => (
                                    <div className="completeContainer" key={i}>
                                        <div className="warrantyItem">
                                            {product.code}
                                        </div>
                                        <div className="warrantyItem paddingX">
                                            <input
                                                name="warranty"
                                                type="string"
                                                onChange={(e) =>
                                                    handleWarrantyChange(e, i)
                                                }
                                                defaultValue={""}
                                            />
                                        </div>
                                    </div>
                                ))}
                                <div className="buttonContainer">
                                    <button
                                                                              className="confirm pointer green"
                                        onClick={() => {
                                            handleFullPayment(modalData);
                                        }}
                                    >
                                        Confirm
                                    </button>
                                    <button
                                      className="cancel pointer red"
                                        onClick={() => {
                                            setFullPaymentMenu(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
