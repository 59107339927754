import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliates, deleteAffiliate } from "../../redux/apiCalls";
import moment from "moment";

export default function WarrantyList() {
    const dispatch = useDispatch();
    const affiliates = useSelector((state) =>
        state.affiliate ? state.affiliate.affiliate : undefined
    );
    const currentUser = useSelector((state) => state.user.currentUser);
    const [isLoaded, setIsLoaded] = useState(false);

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );

    const [filterCategory] = useState({
        createdBy: manager
            ? principle
            : currentUser
            ? currentUser._id
            : undefined,
        status: "deposit",
    });

    const handleDelete = (id) => {
        deleteAffiliate(id, dispatch);
    };

    useEffect(() => {
        const fetchData = async () => {
            const res = await getAffiliates(dispatch, filterCategory);
            if (res) {
                setIsLoaded(true);
            }
        };
        fetchData();
    }, [dispatch, filterCategory]);

    const columns = [
        { field: "code", headerName: "Promo Code", width: 200, sortable: false },
        {
            field: "userId",
            headerName: "User",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="">
                    {value ? `${value.name}` : ""}
                </div>
            ),
        },
        {
            field: "usage",
            headerName: "Usage",
            width: 150,
            sortable: false,
            renderCell: (params) => (
                <div className="">
                    {params.row.usage} / {params.row.maxUsage}
                </div>
            ),
        },
        {
            field: "discount",
            headerName: "Discount",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="">
                    {value} %
                </div>
            ),
        },
        {
            field: "flatDiscount",
            headerName: "Flat Discount",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="">
                    RM {value}
                </div>
            ),
        },
        {
            field: "prepay",
            headerName: "Prepay",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="">
                    RM {value}
                </div>
            ),
        },
        {
            field: "category",
            headerName: "Category",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="">
                    {value}
                </div>
            ),
        },
        {
            field: "expiryDate",
            headerName: "Expiry Date",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">
                    {" "}
                    {value ? moment(value).format("DD/MM/YYYY") : ""}
                </div>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">{value}</div>
            ),
        },
        {
            field: "action",
            headerName: "Action",
            width: 200,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <DeleteOutline
                            className="productListDelete"
                            onClick={() => handleDelete(params.row._id)}
                        />
                    </>
                );
            },
        },
    ];

    return (
        <>
            {isLoaded === true && (
                <div className="productList">
                    <DataGrid
                        rows={affiliates}
                        disableSelectionOnClick
                        columns={columns}
                        getRowId={(row) => row._id}
                        pageSize={20}
                        disableColumnMenu
                    />
                </div>
            )}
        </>
    );
}
