import { useState, useEffect } from "react";
import { registerAdmin, getProducts } from "../../redux/apiCalls";
import { useDispatch } from "react-redux";

export default function NewAdmin() {
    const [inputs, setInputs] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        getProducts(dispatch, "");
    }, [dispatch]);

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleTypeChange = (e) => {
        setInputs((prev) => {
            return { ...prev, type: e.target.value };
        });
    };

    const handleClick = async (e) => {
        const user = { ...inputs };
        e.preventDefault();
        try {
            registerAdmin({ user });
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="newProduct">
            <h1 className="addProductTitle">New Admin</h1>
            <div className="addProductForm">
                <div className="addProductItem">
                    <label>Full Name</label>
                    <input
                        name="name"
                        type="text"
                        placeholder="Name"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Email</label>
                    <input
                        name="email"
                        type="text"
                        placeholder="Email"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Phone Number</label>
                    <input
                        name="phoneNumber"
                        type="text"
                        placeholder="Phone Number"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Password</label>
                    <input
                        name="password"
                        type="password"
                        placeholder="Password"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Address Line One</label>
                    <input
                        name="lineOne"
                        type="string"
                        placeholder="Address Line One"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Address Line Two</label>
                    <input
                        name="lineTwo"
                        type="string"
                        placeholder="Address Line Two"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Zip</label>
                    <input
                        name="zip"
                        type="string"
                        placeholder="Address Zipcode"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>City</label>
                    <input
                        name="city"
                        type="string"
                        placeholder="Address City"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Country</label>
                    <input
                        name="country"
                        type="string"
                        placeholder="Address Country"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Type</label>
                    <select
                        name="type"
                        onChange={handleTypeChange}
                        defaultValue="consignment"
                    >
                        <option value="consignment">Consignment</option>
                        <option value="outright">Outright</option>
                    </select>
                </div>
                <button onClick={handleClick} className="addProductButton">
                    Create
                </button>
            </div>
        </div>
    );
}
