import { createSlice } from "@reduxjs/toolkit";

const affiliateSlice = createSlice({
    name: "affiliate",
    initialState: {
        affiliate: [],
        isFetching: false,
        error: false,
    },
    reducers: {
        getAffiliatesStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getAffiliatesSuccess: (state, action) => {
            state.isFetching = false;
            state.affiliate = action.payload;
        },
        getAffiliatesFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        getCampaignsStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getCampaignsSuccess: (state, action) => {
            state.isFetching = false;
            state.campaign = action.payload;
        },
        getCampaignsFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        addAffiliateStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        addAffiliateSuccess: (state, action) => {
            state.isFetching = false;
            state.affiliate.push(action.payload);
        },
        addAffiliateFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
                deleteAffiliateStart: (state) => {
                    state.isFetching = true;
                    state.error = false;
                },
                deleteAffiliateSuccess: (state, action) => {
                    state.isFetching = false;
                    state.products.splice(
                        state.products.findIndex((item) => item._id === action.payload),
                        1
                    );
                },
                deleteAffiliateFailure: (state) => {
                    state.isFetching = false;
                    state.error = true;
                },
                getAffiliatesReportStart: (state) => {
                    state.isFetching = true;
                    state.error = false;
                },
                getAffiliatesReportSuccess: (state, action) => {
                    state.isFetching = false;
                    state.affiliate = action.payload;
                },
                getAffiliatesReportFailure: (state) => {
                    state.isFetching = false;
                    state.error = true;
                },
    },
});

export const {
    getAffiliatesStart,
    getAffiliatesSuccess,
    getAffiliatesFailure,
    getCampaignsStart,
    getCampaignsSuccess,
    getCampaignsFailure,
    addAffiliateStart,
    addAffiliateSuccess,
    addAffiliateFailure,
    deleteAffiliateStart,
    deleteAffiliateSuccess,
    deleteAffiliateFailure,
    getAffiliatesReportStart,
    getAffiliatesReportSuccess,
    getAffiliatesReportFailure,
} = affiliateSlice.actions;
export default affiliateSlice.reducer;
