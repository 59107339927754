import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    acceptDeliveryOrder,
    getDeliveryOrders,
    updateUser,
    voidDeliveryOrder,
} from "../../redux/apiCalls";
import moment from "moment";
import styled from "styled-components";
import {
    Close,
    VerifiedUser,
    CloudDownload,
    Add,
    PictureAsPdf,
    FileCopy,
    ScreenLockPortrait
} from "@material-ui/icons";
import Table from "../../components/advancedTable";
import createDeliveryOrderForm from "../../components/pdf/createDeliveryOrderForm";
import { Link } from "react-router-dom";
import createDeliveryConsignmentForm from "../../components/pdf/createDeliveryConsignmentForm";
import createReturnForm from "../../components/pdf/createReturnForm";

const ModalContainer = styled.div`
    height: 100%;
    width: 100%;
    opacity: 0.75;
    background: black;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 100;
`;

const OverlayContainer = styled.div`
    overflow: hidden;
`;

const Overlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(white, 0.5);
    visibility: visible;
`;

const ModalDetails = styled.div`
    background-color: rgba(white, 0.5);
    color: black;
    height: 100%;
    width: 50%;
    display: flex;
    z-index: 102;
`;

const ModalItemContainer = styled.div`
    background-color: white;
    color: black;
    height: 100%;
    width: 100%;
    display: grid;
`;

export default function DeliveryOrder() {
    const dispatch = useDispatch();

    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );

    const stockKeeper = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isStockKeeper : null
    );

    const staff = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.type === "staff" : null
    );

    const adminOutlet = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser._id : null
    );

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );

    // eslint-disable-next-line
    const [inputs, setInputs] = useState({
        status: undefined,
        limit: 20,
        page: 0,
        userId: stockKeeper
            ? undefined
            :superadmin
            ? undefined 
            : manager
            ? principle
            : staff
            ? principle
            : adminOutlet,
    });
    const [modalData, setModalData] = useState([]);
    const [voidMenu, setVoidMenu] = useState(false);
    const [acceptMenu, setAcceptMenu] = useState(false);
    const [user, setUser] = useState(undefined);
    const [editModalState, setEditModalState] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [usersData, setUsersData] = useState([]);
    const [transporter, setTransporter] = useState(undefined);
    const [serialNumber, setSerialNumber] = useState(undefined);

    const handleChange = (e) => {
        setUser((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleVoid = (id) => {
        voidDeliveryOrder(id, dispatch);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getDeliveryOrders(dispatch, inputs);
                setUsersData(result ? result.campaigns : []);
                setTotalPages(result ? result.totalPages : 0);
                setIsLoaded(true);
            } catch (error) {
                console.error("Error fetching orders:", error);
            }
        };

        if (!isLoaded) {
            fetchData();
        }
    }, [dispatch, inputs, isLoaded]);

    useEffect(() => {
        if (acceptMenu === true) {
            document.body.style.overflow = "hidden";
            window.scrollTo(0, 0);
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [acceptMenu]);

    const handleAccept = ({ id, transporter, discount }) => {
        if (!transporter) {
            alert("Please enter transporter name");
        } else {
            acceptDeliveryOrder({ id, transporter, discount }, dispatch);
        }
    };

    const handleClick = async () => {
        updateUser(user._id, user, dispatch);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            handleSerialChange(serialNumber);
        }, 1500);

        return () => {
            clearTimeout(timer);
        };
    }, [serialNumber]);

    const handleDelayedChange = (e) => {
        setSerialNumber(e.target.value);
    };

    const handleSerialChange = (e) => {
        setInputs((prev) => {
            return { ...prev, serialNumber: e };
        });
        setIsLoaded(false);
        setSerialNumber(e);
    };

    const columns = [
        {
            Header: "#", // Header for the sequential number column
            accessor: (row, index) => index + 1, // Accessor function to return the sequential number
            width: 20, // Adjust width as needed
            sortable: false, // Optionally, make it sortable or not
            Cell: ({ value }) => <div>{value}</div>, // Display the sequential number in the cell
        },

        {
            accessor: "transferredFrom",
            Header: "Transferred From",
            width: 200,
            sortable: false,
            Cell: ({ value }) => (
                <div className="capitalize">{value ? value.name : ""}</div>
            ),
        },
        {
            accessor: "transporter",
            Header: "Transporter Name",
            width: 200,
            sortable: false,
            Cell: ({ value }) => (
                <div className="capitalize">{value ? value : ""}</div>
            ),
        },
        {
            accessor: "transferredTo",
            Header: "Transferred To",
            width: 200,
            sortable: false,
            Cell: ({ value }) => (
                <div className="capitalize">{value ? value.name : ""}</div>
            ),
        },
        {
            accessor: "deliveryOrderNo",
            Header: "Delivery Order No",
            width: 300,
            sortable: false,
            Cell: ({ value }) => <div className="">{value ? value : ""}</div>,
        },
        {
            accessor: "createdAt",
            Header: "Date",
            width: 300,
            sortable: false,
            Cell: ({ value }) => (
                <div className="">
                    {value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : ""}
                </div>
            ),
        },
        {
            accessor: "status",
            Header: "Status",
            width: 200,
            sortable: false,
            Cell: ({ value }) => <div className="capitalize">{value}</div>,
        },
        {
            accessor: "action",
            Header: "Action",
            width: 150,
            sortable: false,
            Cell: (params) => {
                return (
                    <>
                    
                        {(stockKeeper || process.env.NODE_ENV === "development") && (
                            <>
                                <VerifiedUser
                                    className="pointer"
                                    onClick={() => {
                                        if (
                                            params.row.original.status ===
                                            "pending"
                                        ) {
                                            setModalData(
                                                params.row.original._id
                                            );
                                            setAcceptMenu(true);
                                        } else {
                                            alert(
                                                "Delivery Order has already been handled."
                                            );
                                        }
                                    }}
                                />
                                <Close
                                    className="productListDelete pointer icon"
                                    onClick={() => {
                                        if (
                                            params.row.original.status ===
                                            "pending"
                                        ) {
                                            setModalData(
                                                params.row.original._id
                                            );
                                            setVoidMenu(true);
                                        } else {
                                            alert(
                                                "Delivery Order has already been handled."
                                            );
                                        }
                                    }}
                                />
                            </>
                        )}
                    </>
                );
            },
        },
        {
            accessor: "files",
            Header: "Files",
            width: 200,
            sortable: false,
            Cell: (params) => {
                return (
                    <>
                        <>
                            <CloudDownload
                                className="pointer  mr-1"
                                onClick={() => {
                                    createDeliveryOrderForm(
                                        params.row.original
                                    );
                                }}
                            />
                        </>
                        {superadmin && (
                            <>
                                <FileCopy
                                    className="pointer  mr-1"
                                    onClick={() => {
                                        createDeliveryConsignmentForm(
                                            [params.row.original]
                                        );
                                    }}
                                />

<ScreenLockPortrait
                                    className="pointer  mr-1"
                                    onClick={() => {
                                        createReturnForm(
                                            params.row.original
                                        );
                                    }}
                                />
                            </>
                            
                        )}
                    </>
                );
            },
        },
        {
            accessor: "signed",
            Header: "Signed PDF",
            width: 200,
            sortable: false,
            Cell: (params) => {
                return (
                    <>
                        {stockKeeper && (
                            <>
                                <Link
                                    className="mr-2"
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to={
                                        "/deliveryorderupload/" +
                                        params.row.original._id
                                    }
                                >
                                    <Add />
                                </Link>
                            </>
                        )}
                        {params.row.original.uploadedpdf ? (
                            <a
                                href={params.row.original.uploadedpdf}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                            >
                                <PictureAsPdf />
                            </a>
                        ) : (
                            <></>
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <>
            <div className="productList">
            <div className="samerow">
                            <div className="addProductItem">
                                <input
                                    className="inputFilter"
                                    name="serialNumber"
                                    value={serialNumber}
                                    type="string"
                                    placeholder="Warranty"
                                    onChange={(e) => handleDelayedChange(e)}
                                />
                            </div>
                        </div>
                {isLoaded === true && (
                    <Table
                        data={usersData}
                        columns={columns}
                        pageCount={Math.floor(totalPages / 20) + 2}
                        totalCount={totalPages}
                        updatePagination={setInputs}
                        paginationState={inputs}
                        updateLoaded={setIsLoaded}
                        hidePagination={inputs.limit === 20 ? false : true}
                    />
                )}
            </div>
            {editModalState && (
                <OverlayContainer>
                    <ModalContainer
                        onClick={() => setEditModalState(false)}
                    ></ModalContainer>
                    <Overlay>
                        <ModalDetails>
                            <ModalItemContainer>
                                <div className="updateTitle">
                                    <form className="addProductForm">
                                        <div className="addProductItem">
                                            <label>Full Name</label>
                                            <input
                                                name="name"
                                                type="text"
                                                placeholder={user.name}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="addProductItem">
                                            <label>Email</label>
                                            <input
                                                name="email"
                                                type="text"
                                                placeholder={user.email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="addProductItem">
                                            <label>Phone Number</label>
                                            <input
                                                name="phoneNumber"
                                                type="text"
                                                placeholder={user.phoneNumber}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="addProductItem">
                                            <label>Password</label>
                                            <input
                                                name="password"
                                                type="password"
                                                placeholder={"******"}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="addProductItem">
                                            <label>
                                                Date of Birth (optional)
                                            </label>
                                            <input
                                                name="dob"
                                                type="date"
                                                value={
                                                    user.dob
                                                        ? moment(
                                                              user.dob
                                                          ).format("YYYY-MM-DD")
                                                        : undefined
                                                }
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div
                                            onClick={handleClick}
                                            className="updateProductButton"
                                        >
                                            Update User
                                        </div>
                                    </form>
                                </div>
                            </ModalItemContainer>
                        </ModalDetails>
                    </Overlay>
                </OverlayContainer>
            )}
            {voidMenu && (
                <div className="overlayContainer">
                    <div className="center">
                        <div
                            className="close"
                            onClick={() => setVoidMenu(false)}
                        >
                            <Close></Close>
                        </div>
                        <div className="actionContainer">
                            <div className="updateOrder">
                                <h3 className="updateProductTitle">
                                    Confirm Void Order?
                                </h3>
                                <div className="buttonContainer">
                                    <button
                                        className="confirm pointer green"
                                        onClick={() => {
                                            handleVoid(modalData);
                                            setVoidMenu(false);
                                        }}
                                    >
                                        Confirm
                                    </button>
                                    <button
                                        className="cancel pointer red"
                                        onClick={() => {
                                            setVoidMenu(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {acceptMenu && (
                <div className="overlayContainer">
                    <div className="center">
                        <div
                            className="close"
                            onClick={() => setAcceptMenu(false)}
                        >
                            <Close></Close>
                        </div>
                        <div className="actionContainer">
                            <div className="updateOrder">
                                <h3 className="updateProductTitle">
                                    Confirm Delivery Order?
                                </h3>

                                <div className="actionContainer">
                                    <input
                                        name="productColor"
                                        placeholder="Transporter / Courier Name"
                                        onChange={(e) => {
                                            setTransporter(e.target.value);
                                        }}
                                        defaultValue={""}
                                        className="flex-1 min-w-full p-14 h-60px"
                                    ></input>
                                </div>

                                <div className="buttonContainer">
                                    <button
                                        className="confirm pointer green"
                                        onClick={() => {
                                            handleAccept({
                                                id: modalData,
                                                transporter,
                                            });
                                            setAcceptMenu(false);
                                        }}
                                    >
                                        Confirm
                                    </button>
                                    <button
                                        className="cancel pointer red"
                                        onClick={() => {
                                            setAcceptMenu(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
