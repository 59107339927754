import { DataGrid } from "@material-ui/data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSalesReport } from "../../redux/apiCalls";
import { publicRequest } from "../../requestMethods";
import Select from "react-select";

export default function WarrantyList() {
    const dispatch = useDispatch();
    const salesReport = useSelector((state) => state.order.order);
    const currentUser = useSelector((state) => state.user.currentUser);
    const isFetching = useSelector((state) => state.order.isFetching);

    const TwoDecimalFormatter = new Intl.NumberFormat("en-GB", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );
    const [isLoaded, setIsLoaded] = useState(false);
    const [inputs, setInputs] = useState({
        createdBy: currentUser._id,
        principle: manager
            ? principle
            : currentUser
            ? currentUser._id
            : undefined,
    });

    const getAdmin = async () => {
        const res = process.env.REACT_APP_COUNTRY === "SINGAPORE" ? await publicRequest.get("/users/admin", {
            params: { isAdmin: true },
        }) :  await publicRequest.get("/users/superadmin", {
            params: { isAdmin: true },
        });
        setUsersData(res.data);
    };

    const handleUserFilter = (e) => {
        setInputs((prev) => {
            return { ...prev, createdBy: e.value };
        });
    };

    const [usersData, setUsersData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const res = await getSalesReport(dispatch, inputs);
            if (res) {
                setIsLoaded(true);
            }
        };
        fetchData();
    }, [dispatch, inputs]);

    useEffect(() => {
        if (usersData[0] === undefined) {
            getAdmin();
        }
    }, [usersData]);

    const columns = [
        {
            field: "staff",
            headerName: "Staff",
            width: 300,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="initialPadding">{value ? value : ""}</div>
            ),
        },
        {
            field: "email",
            headerName: "Email",
            width: 300,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="initialPadding">{value ? value : ""}</div>
            ),
        },
        {
            field: "date",
            headerName: "Date",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="initialPadding">{value ? value : ""}</div>
            ),
        },
        {
            field: "monthlySales",
            headerName: "Monthly Sales",
            width: 200,
            sortable: false,
            renderCell: (params) => (
                <div className="initialPadding">
                    {" "}
                    {params
                        ? `${
                              process.env.REACT_APP_COUNTRY === "SINGAPORE" || params.row.staff === "HAOFA Tourbillon Singapore"
                                  ? "$"
                                  : "RM"
                          } ${TwoDecimalFormatter.format(params.row.monthlySales)}`
                        : ""}
                </div>
            ),
        },
    ];

    return (
        <>
            <div className="productList">
                <div className="sameRowContainer">
                    <div className="addProductItem">
                        <Select
                            className="borderBlack"
                            options={usersData.map((user, i) => {
                                return {
                                    label: `${user.name}`,
                                    value: user._id,
                                };
                            })}
                            placeholder={"Outlet"}
                            onChange={handleUserFilter}
                        />
                    </div>
                </div>
                {isLoaded === true && (
                    <DataGrid
                        rows={isFetching === false ? salesReport : []}
                        disableSelectionOnClick
                        columns={columns}
                        pageSize={100}
                        disableColumnMenu
                    />
                )}
            </div>
        </>
    );
}
