import download from "downloadjs";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import moment from "moment";


const createWarrantyReturn = async (original) => {
    try {
        // Create a new PDFDocument
        const pdfDoc = await PDFDocument.create();

        // Embed the Times Roman font
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

        // Add a blank page to the document
        const page = pdfDoc.addPage();
        const fontSize = 20;

        // Get the width and height of the page
        const { height } = page.getSize();

        const jpgUrl =
            process.env.REACT_APP_COUNTRY === "SINGAPORE"
                ? "https://ik.imagekit.io/dnddecpho/Haofa/Haofa_Signoff_SG_1200px_whp1QLEzE.jpg?updatedAt=1693212751559"
                : original.createdBy.address.city === "Johor Bahru"
                ? "https://ik.imagekit.io/dnddecpho/Haofa/jbreceipt_uYJMyCMv_.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1678290172244"
                : "https://ik.imagekit.io/dnddecpho/Haofa/receipt__jfAFa9wP.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1671007440100";
        const jpgImageBytes = await fetch(jpgUrl).then((res) =>
            res.arrayBuffer()
        );
        const jpgImage = await pdfDoc.embedJpg(jpgImageBytes);
        const jpgDims = jpgImage.scale(0.5);
        page.drawImage(jpgImage, {
            x: 0,
            y: height - 6 * fontSize,
            width: jpgDims.width,
            height: jpgDims.height,
        });

        page.drawText(`SERVICE & REPAIR`, {
            x: 205,
            y: height - 8.3 * fontSize,
            size: 22,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Invoice ID:`, {
            x: 60,
            y: height - 10.6 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${original.invoiceId}`, {
            x: 180,
            y: height - 10.6 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Date of Issue:`, {
            x: 60,
            y: height - 11.3 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${moment(new Date()).format("DD MMM YYYY")}`, {
            x: 180,
            y: height - 11.3 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Outlet Name:`, {
            x: 60,
            y: height - 12 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${original.createdBy.name}`, {
            x: 180,
            y: height - 12 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Outlet Contact:`, {
            x: 60,
            y: height - 12.7 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${original.createdBy.phoneNumber}`, {
            x: 180,
            y: height - 12.7 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Outlet Address:`, {
            x: 60,
            y: height - 13.4 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(
            `${original.createdBy.address.lineOne}${original.createdBy.address.lineTwo}`,
            {
                x: 180,
                y: height - 13.4 * fontSize,
                size: 11,
                font: timesRomanFont,
                color: rgb(0, 0, 0),
            }
        );

        page.drawText(
            `${original.createdBy.address.zip}, ${original.createdBy.address.city}, ${original.createdBy.address.country}`,
            {
                x: 180,
                y: height - 14.1 * fontSize,
                size: 11,
                font: timesRomanFont,
                color: rgb(0, 0, 0),
            }
        );

        page.drawText(`Customer:`, {
            x: 60,
            y: height - 15.5 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${original.user.name}`, {
            x: 180,
            y: height - 15.5 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Email:`, {
            x: 60,
            y: height - 16.2 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${original.user.email}`, {
            x: 180,
            y: height - 16.2 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Phone Number:`, {
            x: 60,
            y: height - 16.9 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${original.user.phoneNumber}`, {
            x: 180,
            y: height - 16.9 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("No", {
            x: 50,
            y: height - 20.25 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Model", {
            x: 90,
            y: height - 20.25 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Warranty", {
            x: 200,
            y: height - 20.25 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Return Date", {
            x: 310,
            y: height - 20.25 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${original.type === "complete" ? "Amount" : ""}`, {
            x: 440,
            y: height - 20.25 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: {
                x: 30,
                y: height - 20.75 * fontSize,
            },
            end: {
                x: 560,
                y: height - 20.75 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        page.drawText(`1}`, {
            x: 50,
            y: height - 22 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
            weight: 600,
        });

        page.drawText(`${original.productColor}`, {
            x: 90,
            y: height - 22 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${original.serialNumber}`, {
            x: 200,
            y: height - 22 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${moment(original.createdAt).format("DD MMM YYYY")}`, {
            x: 310,
            y: height - 22 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: {
                x: 30,
                y: height - 24 * fontSize,
            },
            end: {
                x: 560,
                y: height - 24 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        page.drawText(`Remarks: ${original.remarks}`, {
            x: 60,
            y: height - 38 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        const btmBannerUrl =
            process.env.REACT_APP_COUNTRY === "SINGAPORE"
                ? "https://ik.imagekit.io/dnddecpho/Haofa/Haofa_Signoff_SG_1200px_RCwOrSByd.jpg?updatedAt=1702890385105"
                : original.createdBy.address.city === "Johor Bahru"
                ? "https://ik.imagekit.io/dnddecpho/Haofa/jbreceiptBottomBanner_W1gq7vD4-.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1678290172501"
                : "https://ik.imagekit.io/dnddecpho/haofa/Haofa_Signoff_KL2_RPltnPzEh.jpg?updatedAt=1698062422962";
        const btmBannerImageBytes = await fetch(btmBannerUrl).then((res) =>
            res.arrayBuffer()
        );
        const btmBannerImage = await pdfDoc.embedJpg(btmBannerImageBytes);
        const btmBannerDims = btmBannerImage.scale(0.5);
        page.drawImage(btmBannerImage, {
            x: 0,
            y: height - 42.5 * fontSize,
            width: btmBannerDims.width,
            height: btmBannerDims.height,
        });

                // Serialize the PDFDocument to bytes (a Uint8Array)
                const pdfBytes = await pdfDoc.save();

        download(
            pdfBytes,
            `warranty-${original.serialNumber}-${moment(original.createdAt).format("DD/MM/YYYY")}`,
            "application/pdf"
        );
    } catch (err) {
        console.log(err);
    }
};

export default createWarrantyReturn;
