import { useState } from "react";
import "./newProduct.css";
import { addProduct, generateSignature } from "../../redux/apiCalls";
import axios from "axios";
import { useDispatch } from "react-redux";
import colorNames from "../../colors";

export default function NewProduct() {
    const [inputs, setInputs] = useState({});
    const [file, setFile] = useState(null);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleCat = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: [e.target.value] };
        });
    };

    const handleColor = (e) => {
        setInputs((prev) => {
            return { ...prev, color: e.target.value };
        });
    };

    const handleClick = async () => {
        if (file == null) {
            return alert("Image is required for creating new product");
        }

        const fileName = new Date().getTime() + file.name;
        const imageresult = await generateSignature();
        const imageFormData = new FormData();

        imageFormData.append("file", file);
        imageFormData.append(
            "publicKey",
            "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
        );
        imageFormData.append("signature", imageresult.data.signature);
        imageFormData.append("expire", imageresult.data.expire);
        imageFormData.append("token", imageresult.data.token);
        imageFormData.append(
            "fileName",
            `${fileName} - ${imageresult.data.expire}`
        );
        imageFormData.append("folder", "haofa");

        const imageUploadResult = await axios.post(
            "https://upload.imagekit.io/api/v1/files/upload",
            imageFormData,
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Accept: "application/json",
                },
            }
        );

        const product = {
            ...inputs,
            color: [{ img: imageUploadResult.data.url, name: inputs.color }],
            categories: inputs.categories,
        };
        addProduct(product, dispatch);
    };

    return (
        <div className="newProduct">
            <h1 className="addProductTitle">New Product</h1>
            <div className="addProductForm">
                <div className="addProductItem">
                <div className="addProductItem">
                    <label>Image</label>
                    <input
                        type="file"
                        id="file"
                        onChange={(e) => setFile(e.target.files[0])}
                    />
                </div>
                <div className="addProductItem">
                    <label>Color</label>
                    <select name="color" onChange={handleColor}>
                    <option disabled selected value> -- Select Color -- </option>
                        {colorNames.map((color, index) => (
                            <option key={index} value={color.name}>
                                {color.color}
                            </option>
                        ))}
                    </select>
                    </div>
                </div>
                <div className="addProductItem">
                    <label>Title</label>
                    <input
                        name="title"
                        type="text"
                        placeholder="Product Title"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>MY Price</label>
                    <input
                        name="myprice"
                        type="number"
                        placeholder="Enter Price"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>SG Price</label>
                    <input
                        name="price"
                        type="number"
                        placeholder="Enter Price"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Categories</label>
                     <select name="categories" onChange={handleCat}>
                     <option disabled selected value> -- Select Category -- </option>
                        <option value="self-winding">Self-Winding</option>
                        <option value="manual">Manual</option>
                        <option value="crystal">Crystal</option>
                        <option value="carousel">Carousel</option>
                        <option value="mechanical">Automatic Mechanical</option>
                        <option value="women">Ladies</option>
                    </select>
                </div>
                <div className="addProductItem">
                    <label>Model</label>
                    <input
                        name="model"
                        type="text"
                        placeholder="Model"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Movement Model</label>
                    <input
                        name="movementModel"
                        type="text"
                        placeholder="Movement Model"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Power Reserve</label>
                    <input
                        name="powerReserve"
                        type="text"
                        placeholder="Power Reserve"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Vibration Frequency</label>
                    <input
                        name="vibrationFrequency"
                        type="text"
                        placeholder="Vibration Frequency"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Drive</label>
                    <input
                        name="drive"
                        type="text"
                        placeholder="Drive"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Winding Method</label>
                    <input
                        name="windingMethod"
                        type="text"
                        placeholder="Winding Method"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Case Diameter</label>
                    <input
                        name="caseDiameter"
                        type="text"
                        placeholder="Case Diameter"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Case Thickness</label>
                    <input
                        name="caseThickness"
                        type="text"
                        placeholder="Case Thickness"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Strap Width</label>
                    <input
                        name="strapWidth"
                        type="text"
                        placeholder="Strap Width"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Case Material</label>
                    <input
                        name="caseMaterial"
                        type="text"
                        placeholder="Case Material"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Water Resistance</label>
                    <input
                        name="waterResistance"
                        type="text"
                        placeholder="Water Resistance"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Mirror</label>
                    <input
                        name="mirror"
                        type="text"
                        placeholder="Mirror"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Strap Material</label>
                    <input
                        name="strapMaterial"
                        type="text"
                        placeholder="Strap Material"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Type</label>
                    <select name="type" onChange={handleChange}>
                        <option value="watch">Watch</option>
                        <option value="accessory">Accessory</option>
                    </select>
                </div>
                <button onClick={handleClick} className="addProductButton">
                    Create
                </button>
            </div>
        </div>
    );
}
