import "./transactionList.css";
import { DataGrid } from "@material-ui/data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTransactions } from "../../redux/apiCalls";
import moment from "moment";
import { publicRequest } from "../../requestMethods";
import createConsignmentForm from "../../components/pdf/createConsignmentForm";
import createReturnNote from "../../components/pdf/createReturnNote";
import createOutrightForm from "../../components/pdf/createOutrightForm";
import Select from "react-select";

export default function TransactionList() {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.product.stocks);
    const [isLoaded, setIsLoaded] = useState(false);
    const [inputs, setInputs] = useState({
        date: undefined,
        limit: 10,
        page: 0,
        warranty: undefined
    });
    const [usersData, setUsersData] = useState([]);
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );

    

    const limitOptions = [
        {
            label: "Basic",
            value: "10",
        },
        {
            label: "Export",
            value: "9999",
        },
    ];

    const getAdmins = async () => {
        const res = await publicRequest.get("/users/admin", {
            params: { isAdmin: true },
        });
        setUsersData(res.data);
    };

    const getAllAdmins = async () => {
        const res = await publicRequest.get("/users/alladmin", {
            params: { isAdmin: true },
        });
        setUsersData(res.data);
    };

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };
    const handleLimitChange = (e) => {
        setInputs((prev) => {
            return { ...prev, limit: e.value };
        });
    };

    const handleInputFromOutlet = (e) => {
        setInputs((prev) => {
            return { ...prev, transferredFrom: e.value };
        });
    };

    const handleInputToOutlet = (e) => {
        setInputs((prev) => {
            return { ...prev, transferredTo: e.value };
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            const res = await getTransactions(dispatch, inputs);
            if (res) {
                setIsLoaded(true);
            }
        };
        fetchData();
    }, [dispatch, inputs]);

    useEffect(() => {
        if (usersData[0] === undefined) {
            if (process.env.REACT_APP_COUNTRY === "SINGAPORE") {
                getAdmins();
            } else {
                getAllAdmins();
            }
        }
    }, [usersData]);

    const columns = [
        {
            field: "product",
            headerName: "Model",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">
                    Haofa - {value ? value.model : ""}
                </div>
            ),
        },
        {
            field: "productColor",
            headerName: "Color",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">{value ? value : ""}</div>
            ),
        },
        {
            field: "serialNumber",
            headerName: "Serial",
            width: 120,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">{value ? value : 0}</div>
            ),
        },
        {
            field: "transferredFrom",
            headerName: "From",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">{value ? value.name : "-"}</div>
            ),
        },
        {
            field: "transferredTo",
            headerName: "To",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">{value ? value.name : "-"}</div>
            ),
        },
        {
            field: "createdAt",
            headerName: "Transfer Date",
            width: 300,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">
                    {value ? `${moment(value).format("DD-MM-YYYY")}` : "-"}
                </div>
            ),
        },
    ];

    return (
        <div className="productList">
            <div className="samerow">

<div className="addProductItem marginRight">
                    <input
                        className="inputFilter"
                        name="warranty"
                        value={inputs.warranty}
                        type="string"
                        placeholder="Serial Number"
                        onChange={handleChange}
                    />
                </div>

                <div className="sameRowItem marginRight">
                        {superadmin && (
                            <Select
                                options={usersData.map((user, i) => {
                                    return {
                                        label: `${user.name}`,
                                        value: user._id,
                                    };
                                })}
                                onChange={handleInputFromOutlet}
                                name="outlet"
                                placeholder={"From"}
                                onClick={() =>
                                    usersData[0] === undefined ? process.env.REACT_APP_COUNTRY === "SINGAPORE" ? getAdmins() : getAllAdmins() : {}
                                }
                            />
                        )}
                    </div>

                    <div className="sameRowItem marginRight">
                        {superadmin && (
                            <Select
                                options={usersData.map((user, i) => {
                                    return {
                                        label: `${user.name}`,
                                        value: user._id,
                                    };
                                })}
                                onChange={handleInputToOutlet}
                                name="outlet"
                                placeholder={"To"}
                                onClick={() =>
                                    usersData[0] === undefined ? process.env.REACT_APP_COUNTRY === "SINGAPORE" ? getAdmins() : getAllAdmins() : {}
                                }
                            />
                        )}
                    </div>

                    <div className="addProductItem">
                        <input
                            className="inputFilter"
                            name="date"
                            value={inputs.date ? inputs.date : moment(Date.now()).startOf('year').format('YYYY-MM-DD')}
                            type="date"
                            onChange={(e) => handleChange(e)}
                        />
                    </div>

                    <div className="sameRowItem mb-2 marginRight">
                        <>
                        <Select
                            options={limitOptions.map((status, i) => {
                                return {
                                    label: `${status.label}`,
                                    value: status.value,
                                };
                            })}
                            onChange={handleLimitChange}
                            placeholder={"Format"}
                        />
                    </>
                </div>
                </div>
                <div className="block">
                    <button
                        className="blue buttonTop productListButton"
                        onClick={async () => {
                            const res = await getTransactions(dispatch, inputs);
                            if (res && inputs.limit === 9999) {
                                alert("Data ready to be exported for consignment and return");
                            }
                        }}
                    >
                        Search
                    </button>
                    <button
                        className="buttonTop productListButton"
                        onClick={async () => {
                            setInputs({
                                date: "",
                                limit: 10,
                                page: 0,
                            });
                            await getTransactions(dispatch, {
                                date: "",
                                limit: 10,
                                page: 0,
                            });
                        }}
                    >
                        Reset
                    </button>
                    {superadmin && (
                        <button
                            onClick={() => {
                                let payload = [];
                                products.payload.forEach((product) => {
                                    payload.push({
                                        transferredTo: product.transferredTo,
                                        transferredFrom:
                                            product.transferredFrom,
                                        serialNumber: product.serialNumber,
                                        price: process.env.REACT_APP_COUNTRY === "SINGAPORE" ? product.product.price : product.product.myprice,
                                        model: product.product.model,
                                        color: `${product.product.model}-${product.productColor}`,
                                        date: product.createdAt,
                                    });
                                });
                                createReturnNote(payload);
                            }}
                            className="red buttonTop productListButton"
                        >
                            Return
                        </button>
                    )}
                    {superadmin && (
                        <button
                            onClick={async () => {
                                let payload = [];
                                products.payload.forEach((product) => {
                                    payload.push({
                                        transferredTo: product.transferredTo,
                                        transferredFrom:
                                            product.transferredFrom,
                                        serialNumber: product.serialNumber,
                                        price: process.env.REACT_APP_COUNTRY === "SINGAPORE" ? product.product.price : product.product.myprice,
                                        model: product.product.model,
                                        color: `${product.product.model}-${product.productColor}`,
                                        date: product.createdAt,
                                    });
                                });
                                createConsignmentForm(payload);
                            }}
                            className="darkyellow buttonTop productListButton"
                        >
                            Consignment
                        </button>
                    )}
                     {superadmin && (
                        <button
                            onClick={async () => {
                                let payload = [];
                                products.payload.forEach((product) => {
                                    payload.push({
                                        transferredTo: product.transferredTo,
                                        transferredFrom:
                                            product.transferredFrom,
                                        serialNumber: product.serialNumber,
                                        price: process.env.REACT_APP_COUNTRY === "SINGAPORE" ? product.product.price : product.product.myprice,
                                        model: product.product.model,
                                        color: `${product.product.model}-${product.productColor}`,
                                        date: product.createdAt,
                                    });
                                });
                                createOutrightForm(payload);
                            }}
                            className="darkpurple buttonTop productListButton"
                        >
                            Outright
                        </button>
                    )}
                   
                </div>
            {isLoaded === true && 
            <DataGrid
                rows={products ? products.payload : []}
                disableSelectionOnClick
                columns={columns}
                getRowId={(row) => (row ? row._id : {})}
                pageSize={10}
                rowCount={products.totalPages}
                disableColumnMenu
                paginationMode="server"
                onPageChange={async (newPage) => {
                    inputs.page = newPage.page;
                    await getTransactions(dispatch, inputs);
                    setInputs((prev) => ({
                        ...prev,
                        page: newPage.page,
                    }));
                }}
            />
}
        </div>
    );
}