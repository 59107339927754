import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateProductLabel } from "../../redux/apiCalls";

export default function ProductStatus() {
    const location = useLocation();
    const productId = location.pathname.split("/")[2];
    const [input, setInputs] = useState(null);
    const dispatch = useDispatch();

    const product = useSelector((state) =>
        state.product.products.find((product) => product._id === productId)
    );

    const handleLabel = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleClick = async () => {
        updateProductLabel(product._id, input, dispatch);
    };

    return (
        <div className="product">
            <h1 className="addProductTitle">Change Product Label</h1>
            <div className="addProductForm">
                <div className="addProductItem">
                    <label>Label</label>
                    <select name="label" value={input ? input.label : product.label} onChange={handleLabel}>
                        <option disabled selected value>
                            {" "}
                            -- Select Label --{" "}
                        </option>
                        <option value="new">New</option>
                        <option value="hot">Hot</option>
                        <option value="live">Live</option>
                        <option value="normal">Normal</option>
                        <option value="soldout">Sold Out</option>
                    </select>
                </div>
                <button onClick={handleClick} className="addProductButton">
                    Change Label
                </button>
            </div>
        </div>
    );
}
