import { useState, useEffect } from "react";
import "./newWarranty.css";
import { addWarranty, getProducts } from "../../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

export default function NewWarranty() {
    const [inputs, setInputs] = useState({});
    const [product, setProduct] = useState({
        color: [{ id: 1, name: "Please Select Product First" }],
    });
    const dispatch = useDispatch();

    const products = useSelector((state) => state.product.products);
    const currentUser = useSelector((state) => state.user.currentUser);

    useEffect(() => {
        getProducts(dispatch, "");
    }, [dispatch]);

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleProductChange = (e) => {
        setInputs((prev) => {
            return { ...prev, product: e.value };
        });
        setProduct(products.find((product) => product._id === e.value));
    };

    const handleClick = async () => {
        const warranty = { ...inputs, currentUser: currentUser._id };
        if (warranty.product && warranty.productColor && warranty.serialNumber) {
            addWarranty(warranty, dispatch);
        } else {
            alert("Please fill all the fields!");
        }
    };

    return (
        <div className="newProduct">
            <h1 className="addProductTitle">New Warranty</h1>
            <div className="addProductForm">
                <div className="addProductItem">
                    <label>Serial Number</label>
                    <input
                        name="serialNumber"
                        type="text"
                        placeholder="Serial Number"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Product</label>
                    <Select
                        options={products.map((product, i) => {
                            return { label: product.model, value: product._id };
                        })}
                        onChange={handleProductChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Color</label>
                    <select
                        name="productColor"
                        onChange={handleChange}
                        defaultValue={""}
                    >
                        <option>Please Select Color</option>
                        {product
                            ? product.color.map((color, i) => {
                                  return (
                                      <option value={color._id} key={i}>
                                          {color.name}
                                      </option>
                                  );
                              })
                            : {}}
                    </select>
                </div>
                <button onClick={handleClick} className="addProductButton">
                    Create
                </button>
            </div>
        </div>
    );
}
