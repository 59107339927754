import { useState, useEffect } from "react";
import { addAffiliate, getProducts } from "../../redux/apiCalls";
import { useDispatch } from "react-redux";
import { publicRequest } from "../../requestMethods";
import Select from "react-select";

export default function NewAffiliate() {
    const [inputs, setInputs] = useState({
        prepay: 500,
        discount: 0,
        usage: 1,
        maxUsage: 1,
        flatDiscount: 888,
    });
    const [usersData, setUsersData] = useState([]);
    const dispatch = useDispatch();

    const getUsers = async () => {
        const res = await publicRequest.get("/users/all", {
            params: {},
        });
        setUsersData(res.data);
    };

    useEffect(() => {
        if (usersData[0] === undefined) {
            getUsers();
        }
    }, [usersData]);

    useEffect(() => {
        getProducts(dispatch, "");
    }, [dispatch]);

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleChangeUsage = (e) => {
        setInputs((prev) => {
            return { ...prev, maxUsage: e.target.value };
        });
    };

    const handleUserFilter = (e) => {
        setInputs((prev) => {
            return { ...prev, userId: e.value };
        });
    };

    const handleClick = async (e) => {
        const affiliate = { ...inputs };
        if (affiliate.discount > 100) {
            alert("Discount % cannot be more than 100%");
            return;
        }
        if (affiliate.discount === undefined) {
            affiliate.discount = 0;
            return;
        }

        affiliate.discount =
            affiliate.discount === undefined ? affiliate.discount : 0;

        e.preventDefault();
        try {
            addAffiliate(affiliate, dispatch);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <div className="newProduct">
                <h1 className="addProductTitle">New Affiliate</h1>
                <div className="addProductForm">
                    <div className="addProductItem">
                        <label className="required">Affiliate Code</label>
                        <input
                            name="code"
                            type="text"
                            placeholder="Code"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <label className="required">Discount %</label>
                        <input
                            name="discount"
                            type="number"
                            placeholder="Discount"
                            value={inputs.discount}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <label className="required">Flat Discount (RM)</label>
                        <input
                            name="flatDiscount"
                            type="number"
                            placeholder="Flat Discount"
                            value={inputs.flatDiscount}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <label className="">Prepay</label>
                        <input
                            name="prepay"
                            type="number"
                            placeholder="Prepay"
                            value={inputs.prepay}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <label className="required">Usage</label>
                        <input
                            name="maxUsage"
                            type="number"
                            placeholder="Usage"
                            value={inputs.maxUsage}
                            onChange={handleChangeUsage}
                        />
                    </div>
                    <div className="addProductItem">
                        <label>User</label>
                        <Select
                            className="borderBlack"
                            options={usersData.map((user, i) => {
                                return {
                                    label: `${user.name} - ${user.phoneNumber} - ${user.email}`,
                                    value: user._id,
                                };
                            })}
                            placeholder={"Full Name / Phone / Email"}
                            onChange={handleUserFilter}
                        />
                    </div>

                    <div className="addProductItem">
                        <label>Expiry Date</label>
                        <input
                            name="expiryDate"
                            value={inputs.expiryDate}
                            type="date"
                            onChange={handleChange}
                        />
                    </div>
                    <button onClick={handleClick} className="addProductButton">
                        Create
                    </button>
                </div>
            </div>
        </>
    );
}
