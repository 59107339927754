import download from "downloadjs";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import moment from "moment";

const createConsignmentForm = async (original) => {
    console.log(original)
    try {
        const NumberFormatter = new Intl.NumberFormat("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        // Create a new PDFDocument
        const pdfDoc = await PDFDocument.create();

        const signatureImg = "/haofasignature.jpg";
        const signatureImageBytes = await fetch(signatureImg).then((res) =>
            res.arrayBuffer()
        );
        const signatureImage = await pdfDoc.embedJpg(signatureImageBytes);
        const signatureImageDims = signatureImage.scale(0.15);

        const chopImg = "/haofachop.jpg";
        const chopImageBytes = await fetch(chopImg).then((res) =>
            res.arrayBuffer()
        );
        const chopImage = await pdfDoc.embedJpg(chopImageBytes);
        const chopImageDims = chopImage.scale(0.125);

        // Embed the Times Roman font
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

        const totalPages =
            original.length <= 10
                ? 1
                : Math.ceil((original.length - 10) / 10 + 1);

        for (let i = 0; i < totalPages; i++) {
            // Add a blank page to the document
            const page = pdfDoc.addPage();
            const fontSize = 20;

            // Get the width and height of the page
            const { height } = page.getSize();

            if (i === 0) {
                const pageData = original.slice(i * 10, (i + 1) * 10);
                page.drawText(`HAOFA TOURBILLON (M) SDN. BHD.`, {
                    x: 60,
                    y: height - 2 * fontSize,
                    size: 14,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`(1477948-K)`, {
                    x: 300,
                    y: height - 2 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`B-1-01, Residensi The HERZ, Jalan 3/33a, `, {
                    x: 60,
                    y: height - 3 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                 page.drawText(`Kepong Baru, 52100 Kuala Lumpur,`, {
                    x: 60,
                    y: height - 4 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `Tel: 03-6243 0272   Email: haofamalaysia@gmail.com`,
                    {
                        x: 60,
                        y: height - 5 * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                page.drawText(`GST ID: 202201032251`, {
                    x: 60,
                    y: height - 6 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Code:`, {
                    x: 370,
                    y: height - 3 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original[0].transferredTo.debtorCode}`, {
                    x: 410,
                    y: height - 3 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`CN No:`, {
                    x: 370,
                    y: height - 4 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Date:`, {
                    x: 370,
                    y: height - 5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${moment(original[0].date).format("DD/MM/YYYY")}`, {
                    x: 410,
                    y: height - 5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Page:`, {
                    x: 370,
                    y: height - 6 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${i + 1}`, {
                    x: 410,
                    y: height - 6 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Consignment Form`, {
                    x: 230,
                    y: height - 8 * fontSize,
                    size: 14,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`From:`, {
                    x: 60,
                    y: height - 9 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original[0].transferredFrom.name},`, {
                    x: 60,
                    y: height - 10 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original[0].transferredFrom.address.lineOne}`, {
                    x: 60,
                    y: height - 11 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original[0].transferredFrom.address.lineTwo}`, {
                    x: 60,
                    y: height - 12 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `${original[0].transferredFrom.address.zip} ${original[0].transferredFrom.address.city}, ${original[0].transferredFrom.address.country}`,
                    {
                        x: 60,
                        y: height - 13 * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                page.drawText(`Delivered To:`, {
                    x: 370,
                    y: height - 9 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original[0].transferredTo.name},`, {
                    x: 370,
                    y: height - 10 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original[0].transferredTo.address.lineOne}`, {
                    x: 370,
                    y: height - 11 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original[0].transferredTo.address.lineTwo}`, {
                    x: 370,
                    y: height - 12 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `${original[0].transferredTo.address.zip} ${original[0].transferredTo.address.city}, ${original[0].transferredTo.address.country}`,
                    {
                        x: 370,
                        y: height - 13 * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 14.25 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 14.25 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText(`Product Code`, {
                    x: 80,
                    y: height - 15 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Serial Number`, {
                    x: 200,
                    y: height - 15 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Discount %`, {
                    x: 320,
                    y: height - 15 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Unit Price`, {
                    x: 430,
                    y: height - 15 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 15.5 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 15.5 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                pageData.forEach((product, index) => {
                    page.drawText(`${product.color}`, {
                        x: 85,
                        y: height - (16.5 + index * 1.5) * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(`${product.serialNumber}`, {
                        x: 210,
                        y: height - (16.5 + index * 1.5) * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(``, {
                        x: 330,
                        y: height - (16.5 + index * 1.5) * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(
                        `${process.env.REACT_APP_COUNTRY === "SINGAPORE" ? "$" : "RM"} ${NumberFormatter.format(product.price)}`,
                        {
                            x: 430,
                            y: height - (16.5 + index * 1.5) * fontSize,
                            size: 10,
                            font: timesRomanFont,
                            color: rgb(0, 0, 0),
                        }
                    );
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 30.75 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 30.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText(`Remarks:`, {
                    x: 85,
                    y: height - 31.5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 32 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 32 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText(`Please check and receive delivery of above mentioned goods in Good order & Condition. Any discrepancy in the bill or tampering`, {
                    x: 85,
                    y: height - 32.5 * fontSize,
                    size: 8,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`with the packaging seal, should at once be brought to the attention of the person concerned and will be dealt with accordingly.`, {
                    x: 85,
                    y: height - 33 * fontSize,
                    size: 8,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Goods not in order must be returned for replacement within 14 days from date herein.`, {
                    x: 85,
                    y: height - 33.5 * fontSize,
                    size: 8,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

              

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 38.75 * fontSize,
                    },
                    end: {
                        x: 220,
                        y: height - 38.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText("Customer's Chop & Signature", {
                    x: 80,
                    y: height - 39.5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText("HAOFA TOURBILLON (M) SDN. BHD.", {
                    x: 360,
                    y: height - 35.25 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawImage(signatureImage, {
                    x: 385,
                    y: height - 38.5 * fontSize,
                    width: signatureImageDims.width,
                    height: signatureImageDims.height,
                });

                page.drawImage(chopImage, {
                    x: 430,
                    y: height - 38.5 * fontSize,
                    width: chopImageDims.width,
                    height: chopImageDims.height,
                });

                page.drawLine({
                    start: {
                        x: 360,
                        y: height - 38.75 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 38.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText("Authorized Signature", {
                    x: 400,
                    y: height - 39.5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });
            } else {
                const pageData = original.slice(10 + (i - 1) * 10, 10 + i * 10);

                page.drawText(`HAOFA TOURBILLON (M) SDN. BHD.`, {
                    x: 60,
                    y: height - 2 * fontSize,
                    size: 14,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`(1477948-K)`, {
                    x: 300,
                    y: height - 2 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`B-1-01, Residensi The HERZ, Jalan 3/33a, `, {
                    x: 60,
                    y: height - 3 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                 page.drawText(`Kepong Baru, 52100 Kuala Lumpur,`, {
                    x: 60,
                    y: height - 4 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `Tel: 03-6243 0272   Email: haofamalaysia@gmail.com`,
                    {
                        x: 60,
                        y: height - 5 * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                page.drawText(`GST ID: 202201032251`, {
                    x: 60,
                    y: height - 6 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Code:`, {
                    x: 370,
                    y: height - 3 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original[0].transferredTo.debtorCode}`, {
                    x: 410,
                    y: height - 3 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`CN No:`, {
                    x: 370,
                    y: height - 4 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Date:`, {
                    x: 370,
                    y: height - 5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${moment(original[0].date).format("DD/MM/YYYY")}`, {
                    x: 410,
                    y: height - 5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Page:`, {
                    x: 370,
                    y: height - 6 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${i + 1}`, {
                    x: 410,
                    y: height - 6 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Consignment Form`, {
                    x: 230,
                    y: height - 8 * fontSize,
                    size: 14,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`From:`, {
                    x: 60,
                    y: height - 9 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original[0].transferredFrom.name},`, {
                    x: 60,
                    y: height - 10 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original[0].transferredFrom.address.lineOne}`, {
                    x: 60,
                    y: height - 11 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original[0].transferredFrom.address.lineTwo}`, {
                    x: 60,
                    y: height - 12 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `${original[0].transferredFrom.address.zip} ${original[0].transferredFrom.address.city}, ${original[0].transferredFrom.address.country}`,
                    {
                        x: 60,
                        y: height - 13 * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                page.drawText(`Delivered To:`, {
                    x: 370,
                    y: height - 9 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original[0].transferredTo.name},`, {
                    x: 370,
                    y: height - 10 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original[0].transferredTo.address.lineOne}`, {
                    x: 370,
                    y: height - 11 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original[0].transferredTo.address.lineTwo}`, {
                    x: 370,
                    y: height - 12 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `${original[0].transferredTo.address.zip} ${original[0].transferredTo.address.city}, ${original[0].transferredTo.address.country}`,
                    {
                        x: 370,
                        y: height - 13 * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 14.25 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 14.25 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText(`Product Code`, {
                    x: 80,
                    y: height - 15 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Serial Number`, {
                    x: 200,
                    y: height - 15 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Discount %`, {
                    x: 320,
                    y: height - 15 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Unit Price`, {
                    x: 430,
                    y: height - 15 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 15.5 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 15.5 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                pageData.forEach((product, index) => {
                    page.drawText(`${product.color}`, {
                        x: 85,
                        y: height - (16.5 + index * 1.5) * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(`${product.serialNumber}`, {
                        x: 210,
                        y: height - (16.5 + index * 1.5) * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(``, {
                        x: 330,
                        y: height - (16.5 + index * 1.5) * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(
                        `${process.env.REACT_APP_COUNTRY === "SINGAPORE" ? "$" : "RM"} ${NumberFormatter.format(product.price)}`,
                        {
                            x: 430,
                            y: height - (16.5 + index * 1.5) * fontSize,
                            size: 10,
                            font: timesRomanFont,
                            color: rgb(0, 0, 0),
                        }
                    );
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 30.75 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 30.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText(`Remarks:`, {
                    x: 85,
                    y: height - 31.5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 32 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 32 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText(`Please check and receive delivery of above mentioned goods in Good order & Condition. Any discrepancy in the bill or tampering`, {
                    x: 85,
                    y: height - 32.5 * fontSize,
                    size: 8,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`with the packaging seal, should at once be brought to the attention of the person concerned and will be dealt with accordingly.`, {
                    x: 85,
                    y: height - 33 * fontSize,
                    size: 8,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Goods not in order must be returned for replacement within 14 days from date herein.`, {
                    x: 85,
                    y: height - 33.5 * fontSize,
                    size: 8,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 38.75 * fontSize,
                    },
                    end: {
                        x: 220,
                        y: height - 38.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText("Customer's Chop & Signature", {
                    x: 80,
                    y: height - 39.5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText("HAOFA TOURBILLON (M) SDN. BHD.", {
                    x: 360,
                    y: height - 35.25 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });


                page.drawImage(signatureImage, {
                    x: 385,
                    y: height - 38.5 * fontSize,
                    width: signatureImageDims.width,
                    height: signatureImageDims.height,
                });

                page.drawImage(chopImage, {
                    x: 430,
                    y: height - 38.5 * fontSize,
                    width: chopImageDims.width,
                    height: chopImageDims.height,
                });

                page.drawLine({
                    start: {
                        x: 360,
                        y: height - 38.75 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 38.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText("Authorized Signature", {
                    x: 400,
                    y: height - 39.5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });
            }
        }

        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save();

        // Trigger the browser to download the PDF document
        download(pdfBytes, `consignmentForm-${moment(original[0].date).format("DD/MM/YYYY")}`, "application/pdf");
    } catch (err) {
        alert("Failed");
        console.log(err);
    }
};

export default createConsignmentForm;
