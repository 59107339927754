import "./campaign.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCampaigns, updateUser } from "../../redux/apiCalls";
import moment from "moment";
import styled from "styled-components";
import { Close, VerifiedUser } from "@material-ui/icons";
import { acceptCampaign, voidCampaign } from "../../redux/apiCalls";
import { getProducts } from "../../redux/apiCalls";
import excel from "../../components/excel/excel";
import Table from "../../components/advancedTable";
import Select from "react-select";

const statusOptions = [
    {
        label: "Completed",
        value: "completed",
    },
    {
        label: "Cancelled",
        value: "cancelled",
    },
    {
        label: "Pending",
        value: "pending",
    },
];

const deliveryOptions = [
    {
        label: "Delivery",
        value: "delivery",
    },
    {
        label: "Pickup",
        value: "pickup",
    },
];

const pickuplocationOptions = [
    {
        label: "HQ",
        value: "kepong",
    },
    {
        label: "Velocity",
        value: "velocity",
    },
];


const ModalContainer = styled.div`
    height: 100%;
    width: 100%;
    opacity: 0.75;
    background: black;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 100;
`;

const OverlayContainer = styled.div`
    overflow: hidden;
`;

const Overlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(white, 0.5);
    visibility: visible;
`;

const ModalDetails = styled.div`
    background-color: rgba(white, 0.5);
    color: black;
    height: 100%;
    width: 50%;
    display: flex;
    z-index: 102;
`;

const ModalItemContainer = styled.div`
    background-color: white;
    color: black;
    height: 100%;
    width: 100%;
    display: grid;
`;

export default function User() {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.campaign.campaign);
    const watches = useSelector((state) => state.product.products);

    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );

    const marketing = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "marketing"
            : null
    );

    const admin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isAdmin : null
    );
    // eslint-disable-next-line
    const [inputs, setInputs] = useState({
        status: undefined,
        limit: 20,
        page: 0,
    });
    const [name, setName] = useState(undefined);
    const [email, setEmail] = useState(undefined);
    const [phoneNumber, setPhoneNumber] = useState(undefined);
    const [modalData, setModalData] = useState([]);
    const [voidMenu, setVoidMenu] = useState(false);
    const [acceptMenu, setAcceptMenu] = useState(false);
    const [user, setUser] = useState(undefined);
    const [editModalState, setEditModalState] = useState(false);
    // eslint-disable-next-line
    const [productList, setProductList] = useState([]);
    // eslint-disable-next-line
    const [productfilterCategory, setProductFilterCategory] = useState({
        status: "active",
        model: undefined,
    });
    const [watchModel, setWatchModel] = useState(false);
    const [discount, setDiscount] = useState(0);
    const [color, setColor] = useState(undefined);
    const [isLoaded, setIsLoaded] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [usersData, setUsersData] = useState([]);

    const handleChange = (e) => {
        setUser((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    useEffect(() => {
        if (acceptMenu === true) {
            document.body.style.overflow = "hidden";
            window.scrollTo(0, 0);
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [acceptMenu]);

    useEffect(() => {
        if (products[0] === undefined) {
            getProducts(dispatch, productfilterCategory);
        }
    }, [productList, products, dispatch, productfilterCategory]);

    const handleVoid = (id) => {
        voidCampaign(id, dispatch);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (isLoaded === false) {
                try {
                    const result = await getCampaigns(dispatch, inputs);
                    console.log(result)
                    setUsersData(result ? result.campaigns : []);
                    setTotalPages(result ? result.totalPages : 0);
                    setIsLoaded(true);
                } catch (error) {
                    console.error("Error fetching users:", error);
                }
            }
        };

        fetchData();
    }, [dispatch, inputs, usersData, isLoaded]);

    const handleAccept = ({ id, watchModel, discount, color }) => {
        acceptCampaign({ id, watchModel, discount, color }, dispatch);
    };

    const handleClick = async () => {
        updateUser(user._id, user, dispatch);
    };

    const handleNameChange = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, page: 1, name: e.target.value };
        });
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, page: 1, email: e.target.value };
        });
        setEmail(e.target.value);
    };

    const handlePhoneChange = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, page: 1, phoneNumber: e.target.value };
        });
        setPhoneNumber(e.target.value);
    };

    const handleInputStatus = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, page: 1, status: e.value };
        });
    };

    const handleDeliveryChange = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, page: 1, delivery: e.value };
        });
    };

    const handlePickuplocationChange = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, page: 1, pickuplocation: e.value };
        });
    };


    const columns = [
        {
            Header: "#", // Header for the sequential number column
            accessor: (row, index) => index + 1, // Accessor function to return the sequential number
            width: 20, // Adjust width as needed
            sortable: false, // Optionally, make it sortable or not
            Cell: ({ value }) => <div>{value}</div> // Display the sequential number in the cell
        },
        {
            accessor: "status",
            Header: "Status",
            width: 200,
            sortable: false,
            Cell: ({ value }) => (
                <div className="capitalize">{value}</div>
            ),
        },
        {
            accessor: "action",
            Header: "Action",
            width: 200,
            sortable: false,
            Cell: (params) => {
                return (
                    <>
                        {(superadmin || admin) && (
                            <>
                                <VerifiedUser
                                    className="pointer"
                                    onClick={() => {
                                        setModalData(params.row.original._id);
                                        setAcceptMenu(true);
                                    }}
                                />
                                
                                <Close
                                    className="productListDelete pointer icon"
                                    onClick={() => {
                                        setModalData(params.row.original._id);
                                        setVoidMenu(true);
                                    }}
                                />
                            </>
                        )}
                    </>
                );
            },
        },
        {
            accessor: "receipt",
            Header: "Receipt",
            width: 200,
            sortable: false,
            Cell: ({ value }) => {
                return (
                    <div className="flex justify-center align-center productListItem">
                     
                            <img
                                className="campaignListImg"
                                src={value ? value : "https://ik.imagekit.io/dnddecpho/Haofa/blank_2YRK1Fq9C.png?updatedAt=1680849534401"}
                                alt="Receipt"
                                onClick={() => {
                                    window.open(value, "_blank"); // Open image in a new tab if clicked
                                }}
                            />
                    </div>
                );
            },
        },
        {
            accessor: "productPicture",
            Header: "Product Picture",
            width: 200,
            sortable: false,
            Cell: ({ value }) => {
                return (
                    <div className="flex justify-center align-center productListItem">
                        {value && ( // Check if value is not empty or null
                            <img
                                className="campaignListImg"
                                src={value ? value : "https://ik.imagekit.io/dnddecpho/Haofa/blank_2YRK1Fq9C.png?updatedAt=1680849534401"}
                                alt="Receipt"
                                onClick={() => {
                                    window.open(value, "_blank"); // Open image in a new tab if clicked
                                }}
                            />
                        )}
                    </div>
                );
            },
        },
        {
            accessor: "deliveryReceipt",
            Header: "Delivery Receipt",
            width: 200,
            sortable: false,
            Cell: ({ value }) => {
                return (
                    <div className="flex justify-center align-center productListItem">
                        {value && ( // Check if value is not empty or null
                            <img
                                className="campaignListImg"
                                src={value ? value : "https://ik.imagekit.io/dnddecpho/Haofa/blank_2YRK1Fq9C.png?updatedAt=1680849534401"}
                                alt="Receipt"
                                onClick={() => {
                                    window.open(value, "_blank"); // Open image in a new tab if clicked
                                }}
                            />
                        )}
                    </div>
                );
            },
        },
        {
            accessor: "name",
            Header: "Name",
            width: 200,
            sortable: false,
            Cell: ({ value }) => (
                <div className="capitalize">{value}</div>
            ),
        },
        {
            accessor: "phoneNumber",
            Header: "Phone Number",
            width: 200,
            sortable: false,
        },
        {
            accessor: "email",
            Header: "Email",
            width: 300,
            sortable: false,
            Cell: ({ value }) => (
                <div className="">{value ? value : ""}</div>
            ),
        },
        {
            accessor: "createdAt",
            Header: "Date",
            width: 300,
            sortable: false,
            Cell: ({ value }) => (
                <div className="">{value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : ""}</div>
            ),
        },
 
        {
            accessor: "delivery",
            Header: "Delivery / Pickup",
            width: 200,
            sortable: false,
            Cell: ({ value }) => (
                <div className="capitalize">{value}</div>
            ),
        },
        {
            accessor: "model",
            Header: "Product Model",
            width: 200,
            sortable: false,
            Cell: ({ value }) => (
                <div className="capitalize">{value}</div>
            ),
        },
        {
            accessor: "totalPrice",
            Header: "Total Price",
            width: 200,
            sortable: false,
            Cell: ({ value }) => (
                <div className="capitalize">RM {value}</div>
            ),
        },
        {
            accessor: "pickuplocation",
            Header: "Pickup Location",
            width: 200,
            sortable: false,
            Cell: ({ value }) => (
                <div className="capitalize">{value}</div>
            ),
        },
       
        {
            accessor: "address",
            Header: "Address",
            sortable: false,
            width: 600,
        },
    ];

    return (
        <>
            <div className="productList">
            <div className="samerow">
                    <div className="addProductItem">
                        <input
                            className="inputFilter"
                            name="name"
                            value={name}
                            type="string"
                            placeholder="Name"
                            onChange={handleNameChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <input
                            className="inputFilter"
                            name="email"
                            value={email}
                            type="string"
                            placeholder="Email"
                            onChange={handleEmailChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <input
                            className="inputFilter"
                            name="phoneNumber"
                            value={phoneNumber}
                            type="string"
                            placeholder="Phone Number"
                            onChange={handlePhoneChange}
                        />
                    </div>
                    <div className="sameRowItem marginRight">
                        <Select
                            options={statusOptions.map((status, i) => {
                                return {
                                    label: status.label,
                                    value: status.value,
                                };
                            })}
                            onChange={handleInputStatus}
                            placeholder="Status"
                        />
                    </div>
                    <div className="sameRowItem marginRight">
                        <Select
                            options={deliveryOptions.map((status, i) => {
                                return {
                                    label: status.label,
                                    value: status.value,
                                };
                            })}
                            onChange={handleDeliveryChange}
                            placeholder="Delivery"
                        />
                    </div>
                    <div className="sameRowItem marginRight">
                        <Select
                            options={pickuplocationOptions.map((status, i) => {
                                return {
                                    label: status.label,
                                    value: status.value,
                                };
                            })}
                            onChange={handlePickuplocationChange}
                            placeholder="Pickup Location"
                        />
                    </div>
                </div>
                <div className="block mobileGrid">
                    {(superadmin ||
                        marketing) && (
                            <>
                                <button
                                    onClick={() => {
                                        let payload = [];
                                        products.forEach((campaign) => {
                                            payload.push({
                                                name: campaign.name,
                                                email: campaign.email,
                                                phoneNumber:
                                                    campaign.phoneNumber,
                                                model: campaign.model,
                                                totalPrice: campaign.totalPrice,
                                                status: campaign.status,
                                                pickuplocation:
                                                    campaign.pickuplocation,
                                                delivery: campaign.delivery,
                                                address: `${campaign.address.lineOne ? campaign.address.lineOne : ""}, ${campaign.address.zip ? campaign.address.zip : ""} ${campaign.address.city ? campaign.address.city : ""}, ${campaign.address.country ? campaign.address.country : ""}`, 
                                                date: moment(
                                                    campaign.createdAt
                                                ).format("DD/MM/YYYY"),
                                            });
                                        });
                                        excel({
                                            excelData: payload,
                                            fileName: "HaofaCampaign",
                                        });
                                    }}
                                    className="red buttonTop productListButton"
                                >
                                    Export
                                </button>
                            </>
                        )}
                </div>
                {isLoaded === true && (
                    <Table
                        data={usersData}
                        columns={columns}
                        pageCount={Math.floor(totalPages / 20) + 2}
                        totalCount={totalPages}
                        updatePagination={setInputs}
                        paginationState={inputs}
                        updateLoaded={setIsLoaded}
                        hidePagination={inputs.limit === 20 ? false : true}
                    />
                )}
            </div>
            {editModalState && (
                <OverlayContainer>
                    <ModalContainer
                        onClick={() => setEditModalState(false)}
                    ></ModalContainer>
                    <Overlay>
                        <ModalDetails>
                            <ModalItemContainer>
                                <div className="updateTitle">
                                    <form className="addProductForm">
                                        <div className="addProductItem">
                                            <label>Full Name</label>
                                            <input
                                                name="name"
                                                type="text"
                                                placeholder={user.name}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="addProductItem">
                                            <label>Email</label>
                                            <input
                                                name="email"
                                                type="text"
                                                placeholder={user.email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="addProductItem">
                                            <label>Phone Number</label>
                                            <input
                                                name="phoneNumber"
                                                type="text"
                                                placeholder={user.phoneNumber}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="addProductItem">
                                            <label>Password</label>
                                            <input
                                                name="password"
                                                type="password"
                                                placeholder={"******"}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="addProductItem">
                                            <label>
                                                Date of Birth (optional)
                                            </label>
                                            <input
                                                name="dob"
                                                type="date"
                                                value={
                                                    user.dob
                                                        ? moment(
                                                              user.dob
                                                          ).format("YYYY-MM-DD")
                                                        : undefined
                                                }
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div
                                            onClick={handleClick}
                                            className="updateProductButton"
                                        >
                                            Update User
                                        </div>
                                    </form>
                                </div>
                            </ModalItemContainer>
                        </ModalDetails>
                    </Overlay>
                </OverlayContainer>
            )}
            {voidMenu && (
                <div className="overlayContainer">
                    <div className="center">
                        <div
                            className="close"
                            onClick={() => setVoidMenu(false)}
                        >
                            <Close></Close>
                        </div>
                        <div className="actionContainer">
                            <div className="updateOrder">
                                <h3 className="updateProductTitle">
                                    Confirm Void Order?
                                </h3>
                                <div className="buttonContainer">
                                    <button
                                        className="confirm pointer green"
                                        onClick={() => {
                                            handleVoid(modalData);
                                            setVoidMenu(false);
                                        }}
                                    >
                                        Confirm
                                    </button>
                                    <button
                                        className="cancel pointer red"
                                        onClick={() => {
                                            setVoidMenu(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {acceptMenu && (
                <div className="overlayContainer">
                    <div className="center">
                        <div
                            className="close"
                            onClick={() => setAcceptMenu(false)}
                        >
                            <Close></Close>
                        </div>
                        <div className="actionContainer">
                            <div className="updateOrder">
                                <h3 className="updateProductTitle">
                                    Confirm Campaign Order?
                                </h3>

                                <select
                                    name="selectedWatch"
                                    className="flex-1 min-w-full p-14 h-60px"
                                    onChange={(e) => {
                                        setWatchModel(e.target.value);
                                        const selectedOption =
                                            e.target.options[
                                                e.target.selectedIndex
                                            ];
                                        const productModel =
                                            selectedOption.text;

                                        switch (productModel) {
                                            case "2323":
                                                setDiscount(580);
                                                break;
                                            case "1909L":
                                                setDiscount(580);
                                                break;
                                            case "1909L-1":
                                                setDiscount(580);
                                                break;
                                            case "2290":
                                                setDiscount(880);
                                                break;
                                            case "2291":
                                                setDiscount(880);
                                                break;
                                            case "2000":
                                                setDiscount(900);
                                                break;
                                            case "1986":
                                                setDiscount(380);
                                                break;
                                            case "1978":
                                                setDiscount(480);
                                                break;
                                            case "2203":
                                                setDiscount(580);
                                                break;
                                            case "1901-1":
                                                setDiscount(580);
                                                break;
                                            default:
                                                setDiscount(5); // Set default value if no match
                                                break;
                                        }
                                    }}
                                >
                                    <option value="">Select a watch</option>
                                    {watches.map((product, i) => (
                                        <option key={i} value={product._id}>
                                            {product.model}
                                        </option>
                                    ))}
                                </select>

                                <div className="actionContainer">
                                    <select
                                        name="productColor"
                                        onChange={(e) =>
                                            setColor(e.target.value)
                                        }
                                        defaultValue={""}
                                        className="flex-1 min-w-full p-14 h-60px"
                                    >
                                        <option>Please Select Color</option>
                                        {watches
                                            .find(
                                                (watch) =>
                                                    watch._id === watchModel
                                            )
                                            ?.color.map((color, i) => (
                                                <option
                                                    value={color.name}
                                                    key={i}
                                                >
                                                    {color.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>

                                <div className="buttonContainer">
                                    <button
                                        className="confirm pointer green"
                                        onClick={() => {
                                            handleAccept({
                                                id: modalData,
                                                watchModel,
                                                discount,
                                                color,
                                            });
                                            setAcceptMenu(false);
                                        }}
                                    >
                                        Confirm
                                    </button>
                                    <button
                                        className="cancel pointer red"
                                        onClick={() => {
                                            setAcceptMenu(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
