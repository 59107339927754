import { DataGrid } from "@material-ui/data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliatesReport } from "../../redux/apiCalls";

export default function AffiliateList() {
    const dispatch = useDispatch();
    const affiliates = useSelector((state) =>
        state.affiliate ? state.affiliate.affiliate : undefined
    );
    const currentUser = useSelector((state) => state.user.currentUser);
    const [isLoaded, setIsLoaded] = useState(false);

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );

    const [filterCategory] = useState({
        createdBy: manager
            ? principle
            : currentUser
            ? currentUser._id
            : undefined,
        status: "deposit",
    });

    useEffect(() => {
        const fetchData = async () => {
            const res = await getAffiliatesReport(dispatch, filterCategory);
            if (res) {
                setIsLoaded(true);
            }
        };
        fetchData();
    }, [dispatch, filterCategory]);

    const columns = [
        {
            field: "category",
            headerName: "Category",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="">
                    {value}
                </div>
            ),
        },
        {
            field: "usage",
            headerName: "Usage",
            width: 150,
            sortable: false,
            renderCell: (params) => (
                <div className="">
                    {params.row.usage} / {params.row.maxUsage}
                </div>
            ),
        },
        {
            field: "discount",
            headerName: "Discount",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="">
                    {value} %
                </div>
            ),
        },
        {
            field: "prepay",
            headerName: "Prepay",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="">
                    RM {value}
                </div>
            ),
        },
        {
            field: "total",
            headerName: "Total Prepay",
            width: 150,
            sortable: false,
            renderCell: (params) => (
                <div className="">
                    RM {params ? params.row.prepay ? params.row.prepay * params.row.maxUsage : 0 : 0}
                </div>
            ),
        },
    ];

    return (
        <>
            {isLoaded === true && (
                <div className="productList">
                    <DataGrid
                        rows={affiliates}
                        disableSelectionOnClick
                        columns={columns}
                        getRowId={(row) => row._id}
                        pageSize={20}
                        disableColumnMenu
                    />
                </div>
            )}
        </>
    );
}
